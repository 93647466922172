import React, { useEffect, useState } from "react";
import { CookieConsentModal } from "./CookieConsentModal";
import { getCookie } from "./cookieUtils";

export function CookieConsentModalContainer() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const userAccepted = getCookie("cookie_consent_user_accepted");

    if (!userAccepted) {
      setIsOpen(true);
    }
  }, []);

  return <CookieConsentModal isOpen={isOpen} setIsOpen={setIsOpen} />;
}
