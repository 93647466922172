export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 86400000); // days to milliseconds
    expires = "; expires=" + date.toUTCString();
  }
  const currentDomain = window.location.hostname;
  const subdomainParts = currentDomain.split(".");
  const rootDomain = "." + subdomainParts.slice(-2).join(".");
  document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + rootDomain;
};

export const getCookie = name => {
  const nameEQ = name + "=";
  const cookiesArray = document.cookie.split(";");
  for (let cookie of cookiesArray) {
    while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};
