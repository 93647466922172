import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { WrappedIntlProvider } from "./wrapped-intl-provider";
import styles from "../assets/stylesheets/link.scss";
import { disableiOSZoom } from "../utils/disable-ios-zoom";
import DashboardChannel from "../utils/dashboard-channel";
import { Dismiss24Regular, Backspace24Regular } from "@fluentui/react-icons";
import {
  Button,
  Spinner,
  mergeClasses,
  Input,
  FluentProvider,
  Drawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody
} from "@fluentui/react-components";
import { MUDSDarkTheme } from "../utils/fluent-theme";
import { CookieConsentModalContainer } from "./CookieConsentModalConainer";

const MAX_LETTERS = 4;

disableiOSZoom();

class LinkRoot extends Component {
  static propTypes = {
    intl: PropTypes.object,
    store: PropTypes.object,
    linkChannel: PropTypes.object
  };

  state = {
    entered: "",
    failedAtLeastOnce: false
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
    this.dashboardChannel = new DashboardChannel(this.props.store, "");
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown = e => {
    const validKeys = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const key = e.key.toUpperCase();
    if (!validKeys.includes(key)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.addToEntry(key);
  };

  addToEntry = ch => {
    if (this.state.entered.length >= MAX_LETTERS) return;
    const newChars = `${this.state.entered}${ch}`;

    this.setState({ entered: newChars }, () => {
      if (this.state.entered.length === MAX_LETTERS) {
        this.attemptLink(this.state.entered);
      }
    });
  };

  removeChar = () => {
    const entered = this.state.entered;
    if (entered.length === 0) return;
    this.setState({ entered: entered.substring(0, entered.length - 1) });
  };

  attemptLink = async code => {
    try {
      const response = await this.props.linkChannel.attemptLink(code);

      // If there is a profile from the linked device, copy it over if we don't have one yet.
      if (response.profile) {
        const { hasChangedName } = this.props.store.state.activity;

        if (!hasChangedName) {
          this.props.store.update({ activity: { hasChangedName: true }, profile: response.profile });
        }
      }

      this.props.store.update({ credentials: response.credentials });

      const email = response.credentials?.email;

      const user = await this.dashboardChannel.loginWithCode(code, email);

      if (user) {
        this.props.store.update({ authUser: user });
        this.props.store.update({ profile: { ...this.props.store.state.profile, user_color: user.user_color } });
      }

      if (response.path) {
        window.location.href = response.path + `?code=${code}`;
      }
    } catch (e) {
      this.setState({ failedAtLeastOnce: true, entered: "" });

      if (!(e instanceof Error && (e.message === "in_use" || e.message === "failed"))) {
        throw e;
      }
    }
  };

  render() {
    return (
      <FluentProvider style={{ backgroundColor: "var(--primaryColorBackground)" }} theme={MUDSDarkTheme}>
        <WrappedIntlProvider>
          <Drawer separator backdropMotion={null} surfaceMotion={null} size="full" open={true}>
            <DrawerHeader className={styles.header}>
              <DrawerHeaderTitle
                action={
                  <Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} as="a" href="/">
                    <Dismiss24Regular />
                  </Button>
                }
              >
                <FormattedMessage id="link-page.title" defaultMessage="Enter Verse code" />
              </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
              <div className={styles.keypad}>
                {["A", "B", "C", "D", "E", "F", "G", "H", "I"].map((d, i) => (
                  <Button
                    disabled={this.state.entered.length === MAX_LETTERS}
                    className={styles.keypadButton}
                    key={`char_${i}`}
                    onClick={() => this.addToEntry(d)}
                  >
                    {d}
                  </Button>
                ))}
              </div>

              {this.state.entered.length === MAX_LETTERS && (
                <div className={mergeClasses("loading-panel", styles.codeLoadingPanel)}>
                  <Spinner />
                </div>
              )}

              <div className={styles.enteredContents}>
                <div className={styles.subHeader}>
                  {this.state.failedAtLeastOnce ? (
                    <FormattedMessage
                      id="link-page.try-again"
                      defaultMessage="We couldn't find that code.{linebreak}Please try again."
                      values={{ linebreak: <br /> }}
                    />
                  ) : (
                    <FormattedMessage id="link-page.enter-code" defaultMessage="Enter code:" />
                  )}
                </div>

                <div className={styles.entered}>
                  <Input
                    className={styles.charInput}
                    appearance="filled-darker"
                    size="large"
                    value={this.state.entered}
                    pattern="[0-9A-I]*"
                    onChange={(event, data) => {
                      this.setState({ entered: data.value.toUpperCase() }, () => {
                        if (this.state.entered.length === MAX_LETTERS) {
                          this.attemptLink(this.state.entered);
                        }
                      });
                    }}
                    contentAfter={
                      <Button
                        disabled={this.state.entered.length === 0 || this.state.entered.length === MAX_LETTERS}
                        appearance="transparent"
                        onClick={this.removeChar}
                        icon={<Backspace24Regular />}
                      />
                    }
                  />
                </div>
              </div>
            </DrawerBody>
          </Drawer>
          <CookieConsentModalContainer />
        </WrappedIntlProvider>
      </FluentProvider>
    );
  }
}

export default LinkRoot;
