import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  Checkbox,
  DialogSurface,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@fluentui/react-components";
import { setCookie, getCookie } from "./cookieUtils"; // Implement these utility functions as shown below.
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 10000000
  },
  dialog: {
    marginBottom: 0,
    width: "100%!important",
    maxWidth: "100%",
    "@media (min-width: 992px)": {
      marginRight: 0,
      maxWidth: "400px",
      bottom: "25px",
      right: "30px"
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "& > div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "& > div": {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
      }
    }
  },
  dialogActions: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "8px"
  }
});

export const CookieConsentModal = ({ isOpen, setIsOpen }) => {
  const [consentLevel, setConsentLevel] = useState({
    "strictly-necessary": true,
    functionality: true,
    tracking: true,
    targeting: true
  });

  const dialogSurfaceRef = useRef(null);

  useEffect(() => {
    if (isOpen && dialogSurfaceRef.current) {
      const fluentProvider = dialogSurfaceRef.current.closest(".fui-FluentProvider");
      if (fluentProvider) {
        fluentProvider.style.zIndex = "10000000";
      }
    }
  }, [isOpen]);

  const saveConsent = level => {
    const consentLevelString = encodeURIComponent(JSON.stringify(level));
    setCookie("cookie_consent_level", consentLevelString, 365);
    setCookie("cookie_consent_user_consent_token", "token", 365); // Replace 'token' with a generated token if needed.
    setCookie("cookie_consent_user_accepted", "true", 365); // Always 'true' when accept all, decline, or manage.
  };

  const handleAcceptAll = () => {
    const allConsent = {
      "strictly-necessary": true,
      functionality: true,
      tracking: true,
      targeting: true
    };
    saveConsent(allConsent);
    setIsOpen(false);
  };

  const handleDecline = () => {
    const minimalConsent = {
      "strictly-necessary": true,
      functionality: false,
      tracking: false,
      targeting: false
    };
    saveConsent(minimalConsent);
    setIsOpen(false);
  };

  const handleSavePreferences = () => {
    saveConsent(consentLevel);
    setIsOpen(false);
  };

  const styles = useStyles();

  return (
    <Dialog className={styles.backdrop} open={isOpen} modalType="non-modal">
      <DialogSurface className={styles.dialog} ref={dialogSurfaceRef}>
        <DialogBody>
          <DialogTitle action={null}>
            <FormattedMessage id="cookie-consent-modal.title" defaultMessage="We use cookies" />
          </DialogTitle>
          <DialogContent>
            <DialogContent>
              <div className={styles.content}>
                <p>
                  <FormattedMessage
                    id="cookie-consent-modal.message"
                    defaultMessage="We use cookies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from."
                  />
                </p>
                <div>
                  <Checkbox
                    label={
                      <FormattedMessage
                        id="cookie-consent-modal.label.functionality"
                        defaultMessage="Functionality Cookies"
                      />
                    }
                    checked={consentLevel.functionality}
                    onChange={(_, data) => setConsentLevel({ ...consentLevel, functionality: data.checked })}
                  />
                </div>
                <div>
                  <Checkbox
                    label={
                      <FormattedMessage id="cookie-consent-modal.label.tracking" defaultMessage="Tracking Cookies" />
                    }
                    checked={consentLevel.tracking}
                    onChange={(_, data) => setConsentLevel({ ...consentLevel, tracking: data.checked })}
                  />
                </div>
                <div>
                  <Checkbox
                    label={
                      <FormattedMessage id="cookie-consent-modal.label.targeting" defaultMessage="Targeting Cookies" />
                    }
                    checked={consentLevel.targeting}
                    onChange={(_, data) => setConsentLevel({ ...consentLevel, targeting: data.checked })}
                  />
                </div>
                <div>
                  <Checkbox
                    label={
                      <FormattedMessage
                        id="cookie-consent-modal.label.strictly-necessary"
                        defaultMessage="Strictly Necessary Cookies"
                      />
                    }
                    checked={true}
                    disabled
                  />
                </div>
              </div>
            </DialogContent>
          </DialogContent>
          <DialogActions fluid={true} className={styles.dialogActions}>
            <Button appearance="primary" onClick={handleSavePreferences}>
              <FormattedMessage id="cookie-consent-modal.button.accept-all" defaultMessage="Accept" />
            </Button>
            <Button appearance="outline" onClick={handleDecline}>
              <FormattedMessage id="cookie-consent-modal.button.decline" defaultMessage="Decline" />
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

CookieConsentModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

// Default value for open and isOpen is false
CookieConsentModal.defaultProps = {
  setIsOpen: () => false,
  isOpen: true
};
