import { webDarkTheme } from "@fluentui/react-components";
import { getThemeVariables } from "./theme";

const themeVaraibles = getThemeVariables();

export const MUDSDarkTheme = {
  ...webDarkTheme,
  colorNeutralForeground2BrandSelected: themeVaraibles["primary-color"],
  colorCompoundBrandBackgroundHover: themeVaraibles["primary-color-hover"],
  colorCompoundBrandBackground: themeVaraibles["primary-color"],
  colorCompoundBrandBackgroundPressed: themeVaraibles["primary-color-pressed"],
  colorBrandForeground1: themeVaraibles["primary-color"],
  colorCompoundBrandForeground1: themeVaraibles["primary-color"],
  colorCompoundBrandForeground1Hover: themeVaraibles["primary-color-hover"],
  colorCompoundBrandForeground1Pressed: themeVaraibles["primary-color-pressed"],
  colorBrandBackground: themeVaraibles["primary-color"],
  colorBrandBackground2: "#141414",
  colorBrandBackgroundHover: themeVaraibles["primary-color-hover"],
  colorBrandBackgroundPressed: themeVaraibles["primary-color-pressed"],
  colorNeutralForeground2BrandHover: themeVaraibles["primary-color-hover"],
  colorNeutralForeground2BrandPressed: themeVaraibles["primary-color-pressed"],
  colorBrandBackgroundSelected: themeVaraibles["primary-color-pressed"],
  colorNeutralBackground3: "rgba(245, 245, 245, 0.1)",
  colorNeutralBackground1: "#21242C",
  colorBrandStroke1: themeVaraibles["primary-color"],
  colorBrandStroke2Contrast: "#828282",
  primaryColorBackground: "#16181E",
  secondaryColorBackground: "#323232",
  colorNeutralBackground1Pressed: "rgba(255, 255, 255, 0.1)",
  colorNeutralBackgroundDisabled: "#F0F0F0",
  colorCompoundBrandStroke: themeVaraibles["primary-color"],
  colorCompoundBrandStrokeHover: themeVaraibles["primary-color-hover"],
  colorCompoundBrandStrokePressed: themeVaraibles["primary-color-pressed"],
  colorNeutralForegroundInverted: "#FFFFFF",
  label: {
    color: "rgba(147, 147, 147, 1)"
  },
  gray3: "#828282",
  "Gray-3": "#828282"
};
